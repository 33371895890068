import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from "../../../layouts/Default"
import NonStretchedImage from "../../common/NonStretchedImage"
import Nav from "../common/Nav"
import TextBlock from "../common/TextBlock"
import TopSection from "../common/TopSection"
import ImageGrid from "./ImageGrid"
import ImageRow from "./ImageRow"
import Pattern from "./Pattern"
import styles from './styles.module.scss';

const imagesQuery = graphql`
     query {
    image1: file(relativePath: { eq: "cases/xproject/branding/1.webp" }) {
      ...fluidImage
    }
    image2: file(relativePath: { eq: "cases/xproject/branding/2.webp" }) {
      ...fluidImage
    }
    image3: file(relativePath: { eq: "cases/xproject/branding/3.webp" }) {
      ...fluidImage
    }
    image4: file(relativePath: { eq: "cases/xproject/branding/4.webp" }) {
      ...fluidImage
    }
    image5: file(relativePath: { eq: "cases/xproject/branding/5.webp" }) {
      ...fluidImage
    }
  }
  `;

const Xproject = (): JSX.Element => {
  const data = useStaticQuery(imagesQuery);
  const deliveries = ['Konseptutvikling', 'Grafisk design', 'Merkevarestrategi'];

  return (
    <Layout darkMode={true} className={styles.container}>
      <TopSection project={"Ny merkevare og identitet for X Projects"} deliveries={deliveries}>
        <p>
          <b>X Projects</b> er et prosjektbasert medieselskap fra England som kobler kunder fra musikk, underholdning og litteratur med kjøpere over hele kloden.
        </p>
      </TopSection>
      <NonStretchedImage {...data.image1.childImageSharp} />
      <ImageGrid/>
      <ImageRow/>
      <TextBlock wide={true} bottom={true}>
        <p>
          Cras mattis consectetur purus sit amet fermentum.
          Morbi leo risus, porta ac const tur ac, vestibulum at eros. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p>
      </TextBlock>
      <NonStretchedImage {...data.image2.childImageSharp}/>
      <NonStretchedImage {...data.image3.childImageSharp}/>
      <Pattern>
        <NonStretchedImage className={styles.image} {...data.image4.childImageSharp} />
      </Pattern>
      <div className={styles.imageWrapper}>
        <NonStretchedImage className={styles.image} {...data.image5.childImageSharp} />
      </div>
      <Nav/>
    </Layout>
  )
};

export default Xproject;

