import { graphql, useStaticQuery } from "gatsby"
import React from 'react';
import Grid from "../../../common/Grid"
import NonStretchedImage from "../../../common/NonStretchedImage"
import styles from './styles.module.scss';

const cardsQuery = graphql`
     query {
    image1: file(relativePath: { eq: "cases/xproject/cards/1.webp" }) {
      ...fluidImage
    }
    image2: file(relativePath: { eq: "cases/xproject/cards/2.webp" }) {
      ...fluidImage
    }
    image3: file(relativePath: { eq: "cases/xproject/cards/3.webp" }) {
      ...fluidImage
    }
  }
  `;

const ImageRow = (): JSX.Element =>  {
  const data = useStaticQuery(cardsQuery);

  return (
    <Grid className={styles.container}>
      <NonStretchedImage className={styles.image} {...data.image1.childImageSharp} />
      <NonStretchedImage className={styles.image} {...data.image2.childImageSharp} />
      <NonStretchedImage className={styles.image} {...data.image3.childImageSharp} />
    </Grid>
  )
}

export default ImageRow;
