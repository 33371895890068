import React from 'react';
import Xproject from "../components/cases/ProjectX"

const Page = (): JSX.Element => (
  <>
    <Xproject />
  </>
);

export default Page;
